<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Bids</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import bids from '@/services/bids'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import { datePredefined } from '@/utils/predefined'
import { currencyFilter } from '@/utils/currency'

const wrappedBaseActions = actionsWrapper(BaseActionsRequiresWrapper, 'bid', [
  'view-reservation',
  'view-quote',
])

const columns = [
  {
    _t_id: '614fbb01',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '72be229b',
    prop: 'tripId',
    text: 'Trip ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'tripId',
    defaultSort: true,
  },
  {
    _t_id: '63a412b7',
    prop: 'companyId',
    text: 'Company ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'companyId',
  },
  {
    _t_id: '19a1d601',
    prop: 'bidAmount',
    text: 'Bid Amount',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'bidAmount',
    computedText: (item) => currencyFilter(item),
  },
  {
    _t_id: '59b1dd01',
    prop: 'userId',
    text: 'User ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'userId',
  },
  {
    _t_id: '04888420',
    prop: 'createdOn',
    text: 'Created On',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'createdOn',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
  },
  {
    _t_id: '04888790',
    prop: 'acceptedOn',
    text: 'Accepted On',
    sort: true,
    filter: true,
    type: 'date',
    filterType: 'eq',
    sortProp: 'acceptedOn',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    predefined: deepClone(datePredefined),
  },
  {
    _t_id: '04888984',
    prop: 'isActive',
    text: 'Active',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'eq',
    sortProp: 'isActive',
  },
  {
    _t_id: '04888c04',
    prop: 'quoteId',
    text: 'Quote ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
  },
  {
    _t_id: '04888e20',
    prop: 'managedId',
    text: 'Reservation ID',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'managedId',
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        addNewEnabled: false,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        detailKeyId: 'tripId',
        tableId: 'bids_tv_view',
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns,
        collection: 'Bids',
        action: (params) => bids.getBids(params),
      },
    }
  },
}
</script>
